<!--
 * @Descripttion: 努力将代码写的跟诗歌一样优雅
 * @version: V0.0.1
 * @Author: zhengjiajun
 * @Date: 2022-09-13 10:36:04
 * @LastEditors: zhengjiajun
 * @LastEditTime: 2022-09-15 18:00:02
-->
<template>
  <el-dialog
    title="查看"
    :visible.sync="openDialog"
    width="450px"
    class="dialog-vertical"
    @open="dialogOpen"
    
  >
    <el-form
      :model="messageInfo"
      label-position="right"
      label-width="100px"
      class="small-form"
      ref="form"
      v-loading="loading"
    >
      <el-form-item label="用户名称：" prop="nickname">
        <p>{{messageInfo.nickname}}</p>
      </el-form-item>
      <el-form-item :label=" isSchool ?'班级信息：':'会内职务：'" prop="title_name">
        <p>{{messageInfo.title_name}}</p>
      </el-form-item>
      <el-form-item label="留言内容：" prop="content">
        <p>{{messageInfo.content}}</p>
      </el-form-item>
      <el-form-item label="留言时间：" prop="create_time">
        <p>{{messageInfo.create_time}}</p>
      </el-form-item>
    </el-form>
    <span slot="footer">
    </span>
  </el-dialog>
</template>

<script>
import { commentDetail } from "../../api/message";

export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      messageInfo: {

      },
    };
  },
  methods: {
    dialogOpen() {
      this.loading = true
      // 获取详情
      commentDetail({id:this.id}).then(res => {
        console.log(res)
        this.messageInfo = res.data;
        this.loading = false;
      }).catch(err => {
        this.loading = false
      })
    }
  },
  created() {},
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$refs.form.resetFields();
        this.$emit("closeDialog", val);
      },
    },
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
    // 是否是校园版
    isSchool() {
      return this.tenantVersion === "school";
    },
  },
};
</script>

<style></style>
