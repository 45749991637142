<!--
 * @Descripttion: 努力将代码写的跟诗歌一样优雅
 * @version: V0.0.1
 * @Author: zhengjiajun
 * @Date: 2022-09-13 10:36:04
 * @LastEditors: zhengjiajun
 * @LastEditTime: 2022-09-15 17:47:13
-->
<template>
  <el-dialog
    title="新增"
    :visible.sync="openDialog"
    width="450px"
    class="dialog-vertical"
    @open="dialogOpen"
  >
    <el-form
      :model="form"
      :rules="rules"
      label-position="right"
      label-width="100px"
      class="small-form"
      ref="form"
      @submit.native.prevent
      v-loading="loading"
    >
      <el-form-item v-if="!isSchool" label="选择会员：" prop="user_id">
        <el-select v-model="form.user_id" placeholder="请选择">
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.nickname"
            :value="item.user_id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-else label="选择校友：" prop="user_id">
        <el-select v-model="form.user_id" placeholder="请选择">
          <el-option
            v-for="item in userList"
            :key="item.id"
            :label="item.nickname"
            :value="item.user_id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="留言内容：" prop="content">
        <el-input
          type="textarea"
          :rows="5"
          placeholder="请输入留言内容"
          v-model="form.content"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" @click="cancel('form')">取消</el-button>
      <el-button
        :loading="loading"
        size="small"
        type="primary"
        @click="submit('form')"
        >保存</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { newComment, SearchMemberList } from "../../api/message";

export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      rules: {
        id: [{ message: "请选择会员", trigger: "blur", required: true }],
        textarea: [
          { message: "请输入留言内容", trigger: "blur", required: true },
        ],
      },
      // 请求表单
      form: {
        donation_id: this.$route.params.id,
        user_id: '',
        content: "",
      },
      // 会员列表
      userList: [],
      //筛选对象
      filterForm: {
        hash_user_id: 1,
        organization_id: "",
        position_id: "",
        keyword: "", //关键词
        page_size: 99999999, //每页多少条数据
        page: 1,
      },
    };
  },
  methods: {
    dialogOpen() {
      this.loading = true;
      // 获取选择会员列表
      SearchMemberList(this.filterForm)
        .then((res) => {
          console.log(res);
          this.userList = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;

          // 新增留言
          newComment(this.form).then(res => {
            this.$message.success(res.msg)
            this.loading = false;
            this.openDialog = false;
          }).catch(err => {
            this.loading = false;
            this.$message.error(res.msg)

          })
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  created() {},
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$refs.form.resetFields();
        this.$emit("closeDialog", val);
      },
    },
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
    // 是否是校园版
    isSchool() {
      return this.tenantVersion === "school";
    },
  },
};
</script>

<style></style>
