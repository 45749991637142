import api from "@/base/utils/request";
import download from "@/base/utils/download";
import filesUpload from "@/base/utils/upload4";

// 留言列表
export const messageList = (data) => {
  return api({
    url: "admin/donation/comment/index",
    method: "post",
    data,
  });
};
// 审核通过
export const commentcheck = (data) => {
  return api({
    url: "admin/donation/comment/check",
    method: "post",
    data,
  });
};
// 审核不通过
export const commentUncheck = (data) => {
  return api({
    url: "admin/donation/comment/uncheck",
    method: "post",
    data,
  });
};
// 新增留言
export const newComment = (data) => {
  return api({
    url: "admin/donation/comment/newComment",
    method: "post",
    data,
  });
};

// 留言详情
export const commentDetail = (data) => {
  return api({
    url: "/admin/donation/comment/detail",
    method: "post",
    data,
  });
};

// 新增留言
export const setTop = (data) => {
  return api({
    url: "/admin/donation/comment/isTop",
    method: "post",
    data,
  });
};
// 删除留言
export const Pdelete = (data) => {
  return api({
    url: "/admin/donation/comment/delete",
    method: "post",
    data,
  });
};
// 留言放到回收站
export const softDelete = (data) => {
  return api({
    url: "/admin/donation/comment/softDelete",
    method: "post",
    data,
  });
};
// 还原留言
export const putBack = (data) => {
  return api({
    url: "/admin/donation/comment/putBack",
    method: "post",
    data,
  });
};
//获取选择会员列表
export const SearchMemberList = (data) => {
  return api({
    url: "/admin/cyc/user/search",
    method: "post",
    data,
    notCancel: true,
  });
};