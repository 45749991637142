<template>
  <div class="list-page">
    <list-layout
      ref="listLayout"
      :filter-form="filterForm"
      :thead="tenantVersion !== 'school' ? commonThead : schoolThead"
      :tab-arr="tabArr"
      :on-fetch="getMessageList"
      :on-delete="handleDelete"
      :on-put-back="handlePutBack"
    >
      <template v-slot:top>
        <el-button
          class="top-btn"
          type="primary"
          size="small"
          @click="handleAddMessage"
          >新增</el-button
        >
      </template>
      <!-- <template
        v-if="filterForm.tab !== 'checking'"
        #item_donation_status_text="{ row }"
      >
        <list-status-text :text="row.donation_status_text" />
      </template> -->
      <template v-slot:filter>
        <list-filter
          :filterForm="filterForm"
          :pidList="[]"
          :uploadFilter="ok"
        />
      </template>
      <template slot="image" slot-scope="{ row }">
        <span>{{ row.image }}</span>
      </template>
      <template v-slot:tabletop>
        <el-button
          type="primary"
          size="small"
          @click="passArr"
          v-if="filterForm.tab === 'checking' || filterForm.tab === 'rejected'"
          >通过</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="unPassdArr"
          v-if="filterForm.tab === 'checking'"
          >不通过</el-button
        >
        <el-button type="danger" size="small" @click="deletedArr"
          >删除</el-button
        >
      </template>
      <template v-slot:operation="{ row }">
        <el-button
          size="small"
          v-if="filterForm.tab === 'checking' || filterForm.tab === 'rejected'"
          @click="checkRow(row.id)"
          type="text"
          >通过</el-button
        >
        <el-button
          v-if="filterForm.tab === 'checking'"
          size="small"
          @click="unCheckRow(row.id)"
          type="text"
          >不通过</el-button
        >
        <el-button
          size="small"
          v-if="filterForm.tab === 'checked'"
          @click="setTopRow(row)"
          type="text"
          >{{ row.is_top ? "取消置顶" : "置顶" }}</el-button
        >
        <el-button size="small" @click="viewRow(row)" type="text"
          >查看</el-button
        >
      </template>
    </list-layout>
    <AddMessageDialog @closeDialog="refreshList" v-model="showAddMessage" />
    <CheckMessageDialog :id="rowId" v-model="showCheckMessage" />
  </div>
</template>

<script>
import ListStatusText from "@/base/components/List/ListStatusText";
import ListLayout from "@/base/layout/ListLayout";
import listFilter from "../../components/project/MessageFilter";
import AddMessageDialog from "../../components/project/AddMessageDialog";
import CheckMessageDialog from "../../components/project/CheckMessageDialog";
import {
  messageList,
  commentcheck,
  commentUncheck,
  Pdelete,
  softDelete,
  setTop,
  putBack,
} from "../../api/message";
export default {
  components: {
    listFilter,
    ListLayout,
    ListStatusText,
    AddMessageDialog,
    CheckMessageDialog,
  },
  data() {
    return {
      tabArr: [
        { label: "已通过", name: "checked" },
        { label: "待审核", name: "checking", badge: 1 },
        { label: "未通过", name: "rejected" },
        { label: "回收站", name: "deleted" },
      ],
      // 公共版数据表格
      commonThead: [
        { type: "selection" },
        { label: "头像", prop: "avatar", minWidth: 50 },
        { label: "用户名称", prop: "nickname", minWidth: 100 },
        {
          label: "会内职务",
          prop: "title_name",
          minWidth: 150,
        },
        {
          label: "留言内容",
          prop: "content",
          minWidth: 120,
        },
        {
          label: "留言时间",
          prop: "create_time",
          minWidth: 120,
        },
        { label: "操作", type: "operation", minWidth: 250, width: 250 },
      ],
      // 校友版数据表格
      schoolThead: [
        { type: "selection" },
        { label: "头像", prop: "avatar", minWidth: 50 },
        { label: "用户名称", prop: "nickname", minWidth: 100 },
        {
          label: "班级信息",
          prop: "title_name",
          minWidth: 150,
        },
        {
          label: "留言内容",
          prop: "content",
          minWidth: 120,
        },
        {
          label: "留言时间",
          prop: "create_time",
          minWidth: 120,
        },
        { label: "操作", type: "operation", minWidth: 250, width: 250 },
      ],
      // 筛选对象
      filterForm: {
        donation_id: -1, // 捐款id
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        tab: "checked", //当前筛选tab,默认请求已审核的数据
        page_size: 15, //每页多少条数据
        // positionIds:[], // 会内职务
        // content:'', // 留言内容
        // class_id:'' // 班级id （校友版才显示这个筛选条件）
      },
      // 新增留言弹窗
      showAddMessage: false,
      // 查看留言弹窗
      showCheckMessage: false,
      // 操作行的id
      rowId: "",
    };
  },
  methods: {
    // 获取留言列表
    getMessageList(data) {
      return messageList(data);
    },
    // 筛选回调
    ok(e) {
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.$refs.listLayout.getList(this.filterForm);
    },
    // 刷新列表
    refreshList() {
      this.$refs.listLayout.getList();
    },
    // 添加留言
    handleAddMessage() {
      this.showAddMessage = true;
    },
    // 批量通过
    passArr() {
      const selectArr = this.$refs.listLayout.selectArr;
      console.log(selectArr);
      this.checkRow(selectArr);
    },
    // 批量不通过
    unPassdArr() {
      const selectArr = this.$refs.listLayout.selectArr;
      console.log(selectArr);
      this.unCheckRow(selectArr);
    },
    // 多删
    deletedArr() {
      const selectArr = this.$refs.listLayout.selectArr;
      if (selectArr.length > 0) {
        this.$msgbox
          .confirm("确定是否删除", "提示", {
            type: "error",
            confirmButtonText: "确定",
            cancelButtonText: "取消",
          })
          .then((res) => {
            softDelete({ id: selectArr })
              .then((res) => {
                this.$message.success(res.msg);
                this.refreshList();
              })
              .catch((err) => {});
          })
          .catch((err) => console.log(err));
      } else {
        this.$message.info("没有检测到勾选数据");
      }
    },
    // 还原数据
    handlePutBack(data) {
      return putBack({ id: data });
    },
    // 数据删除
    handleDelete(idList, forever = false) {
      console.log(idList, forever);
      return forever ? Pdelete({ id: idList }) : softDelete({ id: idList });
    },
    // 设置置顶
    setTopRow(row) {
      console.log(row);
      this.$confirm(
        `确定${row.is_top ? "取消置顶" : "置顶"}该数据吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.loading = true;
          setTop({ id: row.id, is_top: !row.is_top })
            .then((res) => {
              this.$message.success("操作成功");
              this.loading = false;
              this.refreshList();
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    // 查看留言弹窗
    viewRow(row) {
      this.rowId = row.id;
      this.showCheckMessage = true;
    },
    // 不通过
    unCheckRow(id) {
      this.$prompt("请输入不通过原因", "审核", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.loading = true;
          // 请求接口
          commentUncheck({ id, reason: value })
            .then((res) => {
              this.$message.success(res.msg);
              this.loading = false;
              // 刷新列表
              this.refreshList();
            })
            .catch((err) => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    // 通过
    checkRow(id) {
      this.loading = true;
      commentcheck({ id })
        .then((res) => {
          this.$message.success(res.msg);
          this.loading = false;
          // 刷新列表
          this.refreshList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },

  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
  created() {
    this.filterForm.donation_id = this.$route.params.id;
    console.log(this.tenantVersion);
  },
};
</script>

<style lang="scss" scoped></style>
